.footer {
    display: grid;
    align-items: center;
    justify-items: flex-start;
    grid-auto-flow: column;
    gap: 20px;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
}

.logos {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    column-gap: 40px;
}

.partner {
    max-width: 250px;
}

.shared-studios {
    max-width: 165px;
}

.privacy-policy {
    grid-row: 2;
    grid-column: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    gap: 10px;
    font-weight: 400;
    font-size: 12px;
}

.privacy-policy p {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;
    color: #000000;
}

.privacy-policy a {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 24px;
    color: #000000;
    text-decoration: none;
}

.social-media {
    grid-row: 2;
    grid-column: 2;
    justify-self: flex-end;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    column-gap: 10px;
}

.social-media a {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;
    color: #000000;
    text-decoration: none;
    text-transform: capitalize;
}

@media(max-width: 768px) {
    .footer {
        justify-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);
    }

    .social-media {
        justify-self: center;
        grid-row: 2;
        grid-column: 1;
    }

    .privacy-policy {
        grid-row: 3;
    }
}

@media(max-width: 600px) {
    .logos {
        column-gap: 20px;
    }

    .partner,
    .shared-studios {
        max-width: 130px;
    }
}

@media(max-width: 425px) {
    .privacy-policy {
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
    }

    .privacy-policy p {
        grid-column: 1/3;
        grid-row: 2;
    }
}