.react-add-to-calendar__dropdown {
    z-index: 1;
    width: 100%;
}
.react-add-to-calendar__button {
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    border: 0;
    color: #ffffff;
    background: #0057e4;
    text-decoration: none !important;
    padding: 0 10px;
    display: block;
}
.react-add-to-calendar__button:hover {
    color: #ffffff;
}
.react-add-to-calendar__dropdown {
    padding: 5px 0;
    li {
        padding: 5px 10px;
        &:hover {
            background: #0057e4;
            color: #FFF;
            a{ display: block; color: #fff; outline: none;}
            a:hover,a:focus {
                color: #FFF !important;
                outline: none;
            }
        }
    }
}
