$primery-color: #0057e4;

.user-dd {
    .droplist-item {
        text-decoration: none;
    }

    .dropdown-toggle {
        background: transparent !important;

        &:focus {
            background: transparent !important;
            border: 0;

        }

        border: 0;
    }

    .btn {
        display: flex;
        background: none;
        border: none;
        padding: 0;

        &:focus {
            background: none;
            box-shadow: none;
            outline: none;
        }

        span {
            color: $primery-color;
        }

        .icon-ic-dropdown-blue {
            margin-top: 12%;
        }
    }

    .dropdown-menu {
        top: 15px !important;
    }

    #dropdown-basic {
        &:focus {
            box-shadow: none !important;
        }
    }
}

.user-dd.show {
    span {
        &:before {
            transform: rotate(180deg) !important;
            display: inline-block;
        }
    }
}

.user-dd {
    .dropdown-toggle {
        &:after {
            display: none !important;
        }
    }
}

.header-icon {
    // position: fixed;
    // right: 7.5vh;
    position: absolute;
    right: 7.5vh;
    top: 50%;
    transform: translateY(-50%);
    // z-index: 1;
}