@import '~bootstrap';
@import 'theme';
/* COLORS | THEME | UI | GLOBALS  */

/* HTML DOCUMENT BODY */
body {
    background-color: var(--window-background) !important;
    font-family: var(--font-family) !important;
    font-size: 16px !important;
    line-height: 1 !important;
    min-height: 100vh !important;
    position: relative !important;
    overflow: hidden;
}

/* REACT ELEMENTS */
#root {
    position: relative !important;
    margin: 0 auto !important;
    background-color: var(--window-background) !important;
}

::backdrop {
    background-color: var(--window-background);
}

#control-height {
    height: 100vh;
    width: 0;
    position: absolute;
}

/* MEDIA CLASSES */
@media (max-width: 991px) and (orientation: landscape) {
    .hidden-mobile-landscape {
        display: none;
    }
}

@media (max-width: 991px) and (orientation: portrait) {
    .hidden-mobile-portrait {
        display: none;
    }
}

@media (min-width: 991px) {
    .hidden-desktop {
        display: none;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1600px;
    }
}

.uppercase {
    text-transform: uppercase;
}

.lounge-row {
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
}

.invitee-modal {
    background-color: rgba(0, 0, 0, 0.85) !important;
}

.modal-backdrop {
    display: block !important;
}

.foo-modal-backdrop {
    opacity: 0 !important;
}

.custom-tabs .AgendaContent .ql-align-center {
    text-align: center !important;
}

.custom-tabs .AgendaContent img {
    width: 100% !important;
}

.custom-tabs .AgendaContent .ql-align-left {
    text-align: left !important;
}

.custom-tabs .AgendaContent .ql-align-right {
    text-align: right !important;
}

.agenda-scrollbar div div {
    background-color: #fff !important;
}

.event-header {
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    left: 0 !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
    justify-content: center;
}

.event-header img {
    height: 40px !important;
    width: 40px !important;
}

.d-flex .event-header-title {
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    left: 0 !important;
    padding: 0 240px !important;
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
}

.d-flex .event-header-title .ql-align-center {
    text-align: center !important;
    justify-content: center !important;
    width: 100% !important;

}

.d-flex .event-header-title .ql-align-right {
    text-align: right !important;
    justify-content: end !important;
    width: 100% !important;
}



/* Layout */
$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1600px,
);

@media (max-width: 767px) {
    body {
        min-height: auto !important;
    }
}

body:fullscreen {
    overflow: scroll !important;
}

body:-ms-fullscreen {
    overflow: scroll !important;
}

body:-webkit-full-screen {
    overflow: scroll !important;
}

body:-moz-full-screen {
    overflow: scroll !important;
}

// iframe {
//     pointer-events: none;
//   }