.timer {
    --number-font-size: 48px;
    --unit-font-size: 24px;
}

.timer {
    display: grid;
    row-gap: 16px;
    z-index: 3;
}

.count {
    grid-template-columns: max-content max-content;
    column-gap: 16px;
}

.number {
    width: calc(var(--number-font-size) * 1.7);
    height: calc(var(--number-font-size) * 1.7);
    font-weight: bold;
    font-style: normal;
    font-size: var(--number-font-size);
    line-height: calc(var(--number-font-size) + 10px);
}

.unit {
    font-weight: bold;
    font-style: normal;
    font-size: var(--unit-font-size);
    line-height: calc(var(--unit-font-size) + 4px);
}

@media(max-width: 900px) {
    .timer {
        grid-auto-columns: 1fr;
    }
    .count {
        grid-template-columns: 1fr;
        row-gap: 8px;
    }
}

@media(max-width: 768px) {
    .timer {
        --number-font-size: calc(28px + (48 - 28) * ((100vw - 375px) / (768 - 375)));
        --unit-font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (768 - 375)));
    }
}