.sidebar-collapse {
  padding: 30px 30px 10px 30px;
  width: 300px;
  height: 100vh;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.4);
  background-color: #FFF;
  position: fixed;
  z-index: 10000;
  transform: translateX(-380px);
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in;
  -webkit-transition: all 0.4s ease-in;

  &.in {
    transform: translateX(0px);
  }

  .btn-container {
    position: absolute;
    height: 64px;
    width: 61px;
    box-shadow: 4px 2px 6px -2px rgba(0, 0, 0, 0.4);
    border-radius: 0 10px 10px 0;
    right: -61px;
    top: 20px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-close-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 6px;
      background-color: #fff;
      border: none;
      outline: none;
      position: relative;
      left: -3px;

      svg {
        color: #0057e4;
        font-size: 30px;
      }
    }
  }

  .profile-detail {
    flex: none;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    .profile-image {
      position: relative;
      display: inline-block;
      height: 76px;
      width: 76px;
      border-radius: 100%;
      margin-bottom: 15px;

      img {
        position: relative;
        height: 100%;
        width: auto;
        object-fit: cover;
        z-index: 1;
      }
    }

    .profile-image::before {
      content: '';
      position: absolute;
      border-radius: inherit;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 0;
    }

    .name {
      font-size: 1.16em;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 0.36px;
      text-align: center;
      color: #2c2543;
      margin-bottom: 70px;
    }

    .desc {
      font-size: 0.9em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: left;
      color: #3e445d;
      margin-bottom: 20px;
    }
  }

  .side-menus {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .top-link {
    margin-top: 22px;
    flex: 1;

    li {
      padding: 15px 0;

      a {
        display: flex;
        align-items: center;
        font-size: 1.16em;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: 0.36px;
        text-align: left;
        color: var(--theme-color);
        text-decoration: none;

        .text {
          margin-left: 20px;
        }
      }
    }
  }

  .bottom-link {
    li {
      padding: 8px 0;

      & > a {
        font-size: 12px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.9px;
        text-align: left;
        color: #192150;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  .tocca-item {
    margin-top: 36px;
    padding-bottom: 0;

    .powered-text {
      letter-spacing: normal;
      font-weight: normal;
      font-size: 15px;
      color: #d8d8d8;
      text-transform: none;
    }

    div {
      display: block;
      margin-top: 2px;

      svg {
        height: 24px;
        width: auto;
      }
      img {
        height: 24px;
        width: auto;
      }
    }

  }

}
