@import url(https://fonts.googleapis.com/css?family=Roboto:500);

$white: #fff;
$google-black: #1A1A1A;
$button-active-black: #1A1A1A;

.google-btn {
  width: 184px;
  height: 42px;
  background-color: $google-black;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  margin: 0 auto;
  cursor: pointer;
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-black;
  }
  &:active {
    background: $button-active-black;
  }
}
