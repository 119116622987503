$primery-color: #0057e4;

.logos-header {
    align-items: center;
    justify-items: center;
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
}

.partner-header {
    max-width: 230px;
}

.shared-studios-header {
    max-width: 120px;
}

.user-dd-discovery {
    justify-self: flex-end;

    .droplist-item {
        text-decoration: none;
    }

    .dropdown-toggle {
        background: transparent !important;

        &:focus {
            background: transparent !important;
            border: 0;

        }

        border: 0;
    }

    .btn {
        display: flex;
        background: none;
        border: none;
        padding: 0;

        &:focus {
            background: none;
            box-shadow: none;
            outline: none;
        }

        span {
            color: $primery-color;
        }

        .icon-ic-dropdown-blue {
            margin-top: 12%;
        }
    }

    .dropdown-menu {
        top: 15px !important;
    }

    #dropdown-basic {
        &:focus {
            box-shadow: none !important;
        }
    }
}

.user-dd-discovery.show {
    span {
        &:before {
            transform: rotate(180deg) !important;
            display: inline-block;
        }
    }
}

.user-dd-discovery {
    .dropdown-toggle {
        &:after {
            display: none !important;
        }
    }
}

@media(max-width: 600px) {

    .partner-header,
    .shared-studios-header {
        max-width: 120px;
    }
}

@media(max-width: 500px) {

    .partner-header,
    .shared-studios-header {
        max-width: 80px;
    }
}