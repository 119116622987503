@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

html,
:root {
    --aqua-color: #16ecd1;

    --azul-white-color: #44a5ff;
    --azul-light-color: #489afe;
    --azul-color: #0074ff;
    --azul-shade-color: #165ecc;
    --azul-dark-color: #1251ae;

    --blue-white-color: #f2f6fd;
    --blue-pale-color: #f1f5f7;
    --blue-bright-color: #e7ecf6;
    --blue-accent-color: #d0e0ff;
    --blue-light-color: #c1d5f8;
    --blue-color: #0057e4;
    --blue-shade-color: #0249c2;
    --blue-fade-color: #0344b4;
    --blue-dark-color: rgba(7, 69, 208, 0.9);
    --blue-black-color: #053cbf;

    --gray-white-color: #f5f5ff;
    --gray-light-color: #d7dae5;
    --gray-bright-color: #9ca3bd;
    --gray-color: #535b7c;
    --gray-shade-color: #3e445d;
    --gray-dark-color: #20232f;

    --green-white-color: #bbfad7;
    --green-light-color: #83f3bf;
    --green-color: #2acaa1;

    --marine-color: #072157;
    --marine-dark-color: #192150;
    --marine-black-color: #25283d;

    --mono-white-color: #ffffff;
    --mono-color: #eeeeee;
    --mono-tone-color: #dee1e6;
    --mono-black-color: #000000;

    --orange-color: #ec563b;

    --pink-color: rgb(251, 132, 181);
    --pink-shadow: 0 10px 20px 0 rgba(251, 132, 181, 0.3);

    --purple-color: #7000c5;

    --red-light-color: rgba(255, 229, 228, 1);
    --red-color: #eb4b4b;
    --red-shade-color: #d53a3a;
    --red-dark-color: #ff0909;

    --slate-white-color: #e9ecef;
    --slate-light-color: #e8ecf1;
    --slate-color: #8a9fba;
    --slate-black-color: #283747;

    --yellow-white-color: #ffeeb9;
    --yellow-light-color: #fcdb8a;
    --yellow-color: #f7b924;

    --violet-black-color: #2c2543;
    --black-color: #1a1a1a;
    --theme-color: #1a1a1a;
}

/* GLOBAL VARIABLES APP CSS  */
html,
:root {
    --font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;

    //html = root = window = document = body
    --window-background: var(--blue-pale-color);

    //header | top bar
    --app-width: 100vw;
    --footer-height: 60px;
    --header-height: 56px;
    --header-background: var(--mono-white-color);

    //components
    --chat-width: 400px;

    //containers | pages
    --page-width: 100%;
    --page-with-chat: calc(var(--page-width) - var(--chat-width));

    //border-radius
    --border: solid 1px var(--slate-white-color);
    --radius: 4px;

    //box-shadow
    --shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);

    //theme | tocca | shared
    --theme-light-color: var(--blue-light-color);
    --theme-color: #1a1a1a;
}

/* ----------- STYLES CSS ---------- */
html,
:root {
    --font-family-sans-serif: var(--font-family);
    --theme-gradient: linear-gradient(to top right, var(--theme-color), var(--theme-light-color));
    --mono-gradient: linear-gradient(to right, var(--mono-tone-color), var(--mono-white-color));
    --cyan-gradient: radial-gradient(
        at center,
        var(--blue-white-color) 0%,
        var(--blue-accent-color) 51%,
        var(--blue-light-color) 100%
    );
    --blue-gradient: linear-gradient(-90deg, var(--azul-dark) 0%, var(--azul) 50%, var(--azul-dark) 100%);
}

/* ----------- NORMALIZE CSS ---------- */
html {
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
code {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a {
    color: inherit;
    text-decoration: underline;
}
input {
    border: none;
    box-shadow: none;
    outline: none;
}
